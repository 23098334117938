import type {FormController} from '@wix/form-viewer/controller';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {CustomFieldModel} from '../models/CustomField.model';
import {CashierMandatoryFieldsOverrides} from '../../types/app.types';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {COUNTRIES_WITH_VAT, FORMS_TEMPLATE_IDS} from '../../components/Checkout/constants';
import {
  getContactFormInitialState,
  getContactFormOverrides,
} from '../../components/Checkout/Form/ContactForm/contactForm.utils';
import {
  getAddressFormInitialState,
  getAddressFormOverrides,
} from '../../components/Checkout/Form/AddressForm/addressForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getAdditionalInfoFormOverrides,
} from '../../components/Checkout/Form/AdditionalInfoForm/AdditionalInfoForm.utils';
import {getVatFormInitialState} from '../../components/Checkout/Form/VatIdForm/VatForm.utils';

export class FormsService {
  public validateForm: FormController['validateForm'];
  private validationFunctionResolve!: (validateForm: FormController['validateForm']) => void;
  constructor() {
    const validationFunctionPromise = new Promise<FormController['validateForm']>((resolve) => {
      this.validationFunctionResolve = resolve;
    });

    this.validateForm = async (...args) => {
      const validate = await validationFunctionPromise;
      return validate(...args);
    };
  }

  public setFormValidator = (validateForm: FormController['validateForm']) => {
    this.validationFunctionResolve(validateForm);
  };

  public isAddressValidForBilling = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      cashierMandatoryFields,
    }: {checkoutSettings: CheckoutSettingsModel; cashierMandatoryFields: CashierMandatoryFieldsOverrides}
  ): Promise<boolean> => {
    const contactValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          overridePhone: cashierMandatoryFields?.phone,
        }),
        overrides: getContactFormOverrides({checkoutSettings, overridePhone: cashierMandatoryFields?.phone}),
      });

    const addressValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.ADDRESS,
        values: getAddressFormInitialState(checkoutSettings, addressWithContact.address),
        overrides: getAddressFormOverrides({checkoutSettings, overrides: cashierMandatoryFields}),
      });

    const vatValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.VAT_ID,
        values: getVatFormInitialState(addressWithContact.contact),
        overrides: {},
      });

    const shouldValidateVat =
      addressWithContact.address?.country && COUNTRIES_WITH_VAT.includes(addressWithContact.address?.country);

    return validateAllForms([contactValidator, addressValidator, ...(shouldValidateVat ? [vatValidator] : [])]);
  };

  public isAddressValidForShipping = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      customField,
      isShippingFlow,
    }: {checkoutSettings: CheckoutSettingsModel; customField?: CustomFieldModel; isShippingFlow: boolean}
  ): Promise<boolean> => {
    const contactValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
        }),
        overrides: getContactFormOverrides({checkoutSettings}),
      });

    const addressValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.ADDRESS,
        values: getAddressFormInitialState(checkoutSettings, addressWithContact.address),
        overrides: getAddressFormOverrides({checkoutSettings}),
      });

    const additionalInfoValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
        values: getAdditionalInfoFormInitialState(customField),
        overrides: getAdditionalInfoFormOverrides({checkoutSettings}),
      });

    return validateAllForms([contactValidator, ...(isShippingFlow ? [addressValidator] : []), additionalInfoValidator]);
  };
}

async function validateAllForms(formValidations: (() => Promise<{errors: any[]} | undefined> | undefined)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
